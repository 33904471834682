@import "src/client_customizations/scss/client_master.scss";
$color_1: #666666;
$color_2: #333333;
$color_3: #252525;

.RenderItemTitle {
   font-family: 'Helvetica Neue Roman' !important;
	display: flex;
	justify-content: center;
	font-weight: 400;
	font-size: 12px;
	color: $RenderItem-title-color;
	line-height: inherit;
	margin: 10px 0px 0px 0px;
	text-transform: uppercase;
}
.RenderItemTitleLeft {
   font-family: 'Helvetica Neue Roman' !important;
	display: flex;
	font-weight: 400;
	font-size: 12px;
	color: $RenderItem-title-color;
	line-height: inherit;
	margin: 10px 0px 0px 0px;
	text-transform: uppercase;
}
.RenderItemValue {
  	font-family: 'Helvetica Neue' !important;
	margin-bottom: 15px !important;
	display: flex;
	justify-content: center;
	font-weight: bold;
	color: $RenderItem-item-value-color;
	.denominator {
		font-weight: 400;
		font-size: 0.8rem;
		color: $RenderItem-item-value-denominator-color;
	}
}
.RenderItemValueLeft {
	font-weight: 400 !important;
	margin-bottom: 15px !important;
	font-size: 18px;
	display: flex;
	font-weight: bold;
	color: $RenderItem-item-value-color;
	.denominator {
		font-weight: 400;
		font-size: 0.8rem;
		color: $RenderItem-item-value-denominator-color;
		opacity: 0.45;
	}
}
.h2.RenderItemValue {
	font-size: 1.75rem;
}
p.RenderItemValue {
	font-size: 1.2rem;
}
