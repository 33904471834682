@import "src/client_customizations/scss/client_master.scss"; 

.EVCard p {


  font-family: 'Helvetica Neue Thin';
  width: 100%;
  margin-bottom: 0;
}

.EVCardTop, > * {
  padding: 8px;
}

.EVCardTop {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  position: relative;

  /* For image in top-right corner */

  img {
    padding: 0;
  }

  .badge-locally-available, .badge-fuel-type {
    position: absolute;
    display: block;
  }

  .badge-locally-available img {
    width: 21px;
    padding: 0;
  }

  .badge-fuel-type {
    img {
      width: 12px;
      padding: 0;
    }

    right: 8px;
    top: 8px;
  }

  .badge-locally-available {
    right: 28px;
    top: 8px;
  }
}
.EVCard {

  font-family: 'Helvetica Neue Roman';
  display: flex;
  flex-direction: column;
  height: 100%;
}

.EVCardBottom {

  margin-top: auto;
  border-top: $EVCard-EVCardBottom-border;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  

  > * {
    flex-grow: 1;
  }

}

.EVCardBottomWithLink {

  font-family: 'Helvetica Neue' !important;
  text-align: center;
  padding: 0 0.75rem 1rem 0.75rem;

  .btn {
    font-size: 0.85rem;
    padding: 0.5rem;
  }
}

@media (min-width: 576px) {
  .EVCardTop {
    padding: 15px 15px 10px 15px;
  }

  // .EVCardBottom > * {
  //   padding: 10px 15px;
  // }

  .EVCardTop {
    .badge-fuel-type {
      right: 15px;
      top: 15px;
    }

    .badge-locally-available {
      right: 35px;
      top: 15px;
    }
  }
}