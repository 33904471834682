@import "src/client_customizations/scss/client_master.scss";

/* Apply yellow span underline for mobile views */
.Header {
     .communitySection {
          margin-top: 20px;
          display: flex;
          justify-content: flex-end;
          font-size: 12px;
          font-weight: 700;
          color: #666666;

          @media (max-width: 768px) {
               flex-wrap: wrap;
          }
     }
     .language-filter {
          a {
               font-size: 12px;
               font-weight: 700;
               color: #666666 !important;
          }
          .nav-link {
               @media (max-width: 1208px) {
                    margin-left: 50px;
               }
               padding: 0 !important;
               margin-left: 100px;
          }
     }
     .headerButton {
          font-size: 12px;
          font-weight: 700;
          color: #666666;
          background-color: white;
          border: none;
          cursor: pointer;
          &.blue {
               color: #00819e !important;
          }
     }
     background-color: $Header-background-color;

     a {
          color: $Header-link-color;
     }
     .navbar {
          padding-top: 0;
          padding-bottom: 0;

          .header-logo {
               width: 400px;
               @media (max-width: 768px) {
                         width: 280px;
                    }
          }

         

          .navbar-brand {
               padding-top: 0;
               padding-bottom: 0;
               img {
                    width: 160px;
               }
          }
          .navbar-collapse {
               a.active {
                    span {
                         color: $Header-navbar-link-color;
                         text-decoration: none;
                    }
               }

               

               .navbar-nav {
                    display: flex;
                    column-gap: 36px;
                    align-items: center;
               }

               .dropdown-item {
                    border-radius: 0;
               }
          }
          .dropdown-menu {
               top: 50px;
               border-radius: 3px;

               .dropdown-item {
                    font-size: 13px;

                    &.active,
                    &:active {
                         background-color: transparent;
                         color: $Header-navbar-link-color;
                    }
               }
          }
     }
     .nav-item {
          font-size: 16px;
          margin: 5px;
     }
}
.skip-to-content-link {
     position: absolute;
     top: -1000px;
     left: -1000px;
     height: 1px;
     width: 1px;
     text-align: left;
     overflow: hidden;
}
a.skip-to-content-link {
     &:active {
          left: 75px;
          top: 20px;
          width: auto;
          height: auto;
          overflow: visible;
     }
     &:focus {
          left: 75px;
          top: 20px;
          width: auto;
          height: auto;
          overflow: visible;
     }
     &:hover {
          left: 75px;
          top: 20px;
          width: auto;
          height: auto;
          overflow: visible;
     }
}
.navbar-toggler {
     border-color: $Header-navbar-toggler-border-color;
     color: $Header-navbar-toggler-color;
     .navbar-toggler-icon {
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(105,105,105, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
     }
}
@media (min-width: 992px) {
     .Header {
          .navbar {
               padding: 0;
               .navbar-collapse {
                    a {
                         padding: 10px;
                         border-radius: 4px;
                         font-weight: 700;
                         letter-spacing: 0.6px;
                         text-decoration: none;
                    }
                    a.active {
                         font-weight: bold;
                    }
               }
          }
     }
}
