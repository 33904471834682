@import "src/client_customizations/scss/client_master.scss";

.IncentiveCard {
	margin: 0 17px 34px;
	height: 369px;
	max-height: 369px;
	width: 255px;
	max-width: 255px;

	background: #FFFFFF;
	box-shadow: 0px 20px 41px rgba(0, 0, 0, 0.1);
	border-radius: 2px;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	.colorBar {
		margin: 0 0 8px; 
		min-height: 8px;
		height: 8px;
		width: 255px;		
		background-color: #00A9CE;
	}

	.title {		
		color: #333333;
		margin: 0 0 24px;

		font-family: "Helvetica Neue";
		font-style: normal;
		font-weight: 700;
		font-size: 14px;
		line-height: 24px;

		display: flex;
		align-items: center;
		text-align: center;
		letter-spacing: 0.75px;
		text-transform: uppercase;
	}

	.value {	
		margin: 0 0 26px;	
		font-family: "Helvetica Neue";
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 48px;

		display: flex;
		align-items: center;
		text-align: center;

		color: #333333;
	}

	.text {	
  	   font-family: 'Helvetica Neue Thin' !important;
		margin: 0 0 31px;	
		max-width: 223px;


		font-style: normal;
		font-weight: 300;
		font-size: 18px;
		line-height: 24px;

		text-align: center;

		color: #333333;
	}

	hr {
		margin: auto 0 12px;
		width: 94%;
		border: 1px solid #DADADA;
	}
	.cardTopArea {    
		display: flex;
    height: 269px;
    min-height: 269px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
	}
	.description {
  	   font-family: 'Helvetica Neue Thin' !important;
		margin: 0 0 12px;
		max-width: 223px;
		max-height: 88px;
		height: 88px;

		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 22px;

		color: #000000;
	}
}
.IncentiveCardBody {
	padding: 1rem;
	text-align: center;
	color: $IncentiveCard-card-body-color;
	border-radius: 0;
	.card-title {
		margin-top: 1rem;
		color: $IncentiveCard-card-title-color;
	}
	img {
		margin-top: -12px;
		margin-bottom: -16px;
		width: 60px;
	}
}
.IncentiveCardBottom {
	padding: 1rem;
	text-align: center;
}
@media (min-width: 768px) {
	.IncentiveCard {
		flex-basis: 32%;
	}
	.IncentiveCardBody {
		padding: 1.5rem;
	}
	.IncentiveCardBottom {
		padding: 1.5rem;
	}
}
.displayNone {
	display: none !important;
}
.showBtn {
	border: none;
	background-color: transparent;

	color: #00819E;
}
.showAllCard {
	min-height: 369px !important;
	height: auto !important;
	max-height: 1000px !important;

	.description {
		min-height: 88px !important;
		height: auto !important;
		max-height: 1000px !important;
	}
}