@import "src/client_customizations/scss/client_master.scss";

@media (max-width: 425px) {
     #learnScroll {
          .tab-header {
               padding: 0 !important;

               h1 {
                    font-size: 18px !important;
               }
          }
     }
}

#FAQ {
     color: #333333;
     .bold {
          font-family: "Helvetica Neue Roman" !important;
          font-weight: 800;
     }

     .frequently {
          font-size: 24px;
          letter-spacing: 0.75px;
          font-weight: 400;
          margin-bottom: 35px;
          margin-top: 35px;
     }

     .bottom-footer {
          margin-top: 75px;
          background-color: white;
          display: flex;
          flex-direction: column;
          align-items: center;

          .cbtns {
               font-family: "Helvetica Neue Roman" !important;
               display: flex;
               justify-content: space-around;
               flex-direction: row;

               @media (max-width: 767px) {
                    display: block;
               }
          }

          .btn {
               display: flex;
               border-radius: 0;
               cursor: pointer;
               background-color: #00819e;
               color: white;
               padding: 10px 30px;
               margin: 20px;

               &.active {
                    background-color: white;
                    color: #00819e;
                    border: 1px solid #00a9ce;
               }
          }

          .bottom-header {
               font-size: 24px;
          }
     }

     .tab-section {
          background-color: white;
          padding:50px 30px 40px;
          overflow: hidden;
          margin-bottom: 15px;

          h1 {
               color: #000000;
          }

          &.active {
               max-height: none;
               overflow: visible;

               .tab-header {
                    h1 {
                         color: #000000;
                    }

                    p {
                         display: none;
                    }
               }

               .tab-content {
                    display: block;
               }
          }

          .tab-header {
               position: relative;
               padding: 0px 0 0;

               .container {
                    position: relative;

                    @media (max-width: 767px) {
                         padding-right: 75px;
                    }
               }

               h1 {
                    font-size: 25px;
                    -webkit-transition: all 0.3s;
                    transition: all 0.3s;
                    font-weight: 600;
                    margin:0;
                    line-height: 1.3em;
               }

               p {
                    font-size: 14px;
                    max-width: 600px;
               }

               .toggle-button {
                    width: 50px;
                    height: 50px;
                    border-radius: 50px;
                    position: absolute;
                    right: 15px;
                    top: -15px;
                    cursor: pointer;

                    img {
                         position: absolute;
                         left: 0;
                         right: 0;
                         margin: auto;
                         top: 54%;
                         transform: translateY(-50%);
                         -webkit-transition: all 0.3s;
                         transition: all 0.3s;
                         -webkit-touch-callout: none;
                         -webkit-user-select: none;
                         -khtml-user-select: none;
                         -moz-user-select: none;
                         -ms-user-select: none;
                         user-select: none;
                    }
               }
          }

          .tab-content {
               font-family: "Helvetica Neue Thin" !important;
               padding-left: 17px;
               margin-top: 30px;
               font-size: 16px;
               font-weight: 100;
               width: 85%;
               margin-bottom: 30px;
               display: none;

               .link {
                    text-decoration: underline;
                    color: #00819e;
               }

               .margin-top {
                    margin-top: 10px;
               }

               .content-list {
                    margin-top: 2rem;
               }

               .chargers-image-columns {
                    margin-top: 25px;
                    display: flex;
                    justify-content: space-between;

                    @media (max-width: 991px) {
                         flex-direction: column;
                         align-items: center;
                    }

                    img {
                         max-width: 320px;
                    }
               }
          }

          @media (max-width: 576px) {
               .toggle-button {
                    width: 25px !important;
                    height: 25px !important;
                    top: 3px !important;

                    img {
                         width: 15px;
                         height: 10px;
                         top: 56% !important;
                    }
               }
          }
     }

     .sideBySide {
          margin-top: 30px;

          @media(max-width:991px) {
               &.reverse {
                    .row.container {
                         flex-direction: column-reverse;
                    }
               }
          }

          .row-container {
               max-width: 100% !important;
               flex-direction: column !important;

               .col {
                    margin: 0 !important;
                    width: 100% !important;
                    height: auto !important;
               }

               .col.right {
                    padding: 1px !important;

                    img {
                         max-width: 100% !important;
                    }
               }
          }
          font-family: "Helvetica Neue";
          display: flex;
          flex-direction: row;
          justify-content: center;

          .container {
               display: flex;
          }

          .row {
               display: flex;
               align-items: stretch;
               flex-wrap: nowrap;
               gap:30px;

               @media(max-width:991px) {
                    flex-wrap: wrap;
                    padding:0;
               }
          }

          .col {
               flex: auto;
          }

          .text {
               width:50%;
               display: flex;
               flex-direction: column;
               background-color: white;
               font-size: 16px;
               padding: 2rem;
               font-weight: 150;

               @media(max-width:991px) {
                    width: 100%;
               }

               .text-title {
                    display: flex;
                    font-weight: 600;
               }

               .small-space {
                    margin-top: 1rem;
               }

               .large-space {
                    margin-top: 1rem;
               }

               .bottom,
               .top,
               .middle {
                    font-family: "Helvetica Neue Thin" !important;
               }
          }

          .image {
               width: 50%;
               display: flex;
               align-items: center;
               padding:0;
               overflow: hidden;

               @media(max-width:991px) {
                    width: 100%;
               }

               img {
                    width:100%;
                    height:auto;
               }
          }
     }
}

.flex-row-center {
     display: flex;
     margin:0 auto;
     justify-content: center;
}
