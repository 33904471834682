@import "src/client_customizations/scss/client_master.scss";

section.container.homepage-jumbotron {
     display: flex;
     align-items: center;
}
.homepage-jumbotron {
     .big-promise {
          padding: $HomePage-big-promise-padding;
          background-color: $HomePage-big-promise-background-color;
          color: $HomePage-big-promise-color;
          text-shadow: $HomePage-big-promise-text-shadow;
          min-height: $HomePage-big-promise-min-height;
          width: 100%;
     }
}

@media (max-width: 425px) {
     .img-container {
          background-size: cover !important;
     }

     .link-card-container {
          display: flex;
          flex-direction: column !important;
          justify-content: center;
          align-items: center;
     }

     .evc-card {
          div {
               display: flex !important;
               flex-direction: column !important;
               justify-content: center !important;
               align-items: center !important;
          }
     }

     h1 {
          text-align: center !important;
     }
}
#link-card-buttons {
     background-color: $HomePage-big-promise-buttons-background-color;
     padding: 3rem 1rem 1rem 1rem;
}
#why-choose-evs {
     padding: 0rem 1rem;
}
.big-promise-caption {
     top: 0;
     left: 0;
     position: absolute;
     padding: 1.5rem 1rem;
}

.big-promise-text {
     @media (max-width: 425px) {
          display: flex;
          margin: 0px;
     }
     position: absolute;
     top: 105px;
     left: 0;
     right: 0;
     margin: 0 auto;

     div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
     }

     h1 {
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 700;
          font-size: 48px;
          line-height: 65px;
          text-align: center;
          letter-spacing: 0.15px;
          color: #ffffff;
     }

     p {
          margin: 16px 0 24px;
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 18px;

          text-align: center;
          letter-spacing: 0.15px;

          color: #ffffff;
     }

     a {
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          line-height: 24px;

          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.15px;

          padding: 15px 33px;

          color: #000;

          background-color: #ffb81c;
          border: none;
          border-radius: 2px;

          margin-top: 20px;
     }
}
