@import "src/client_customizations/scss/client_master.scss";
.Footer {
	.footer-elements {
		color: #FFF;
		display: flex;
		justify-content: start;
		.subtext {
			position: relative;
			font-family: Arial, Helvetica;
			.white-text {
				cursor: pointer;
				color: white !important;
			}
		}
		.logos {
			display: flex;
			.box {
				width: 24px;
				height: 24px;
				border-radius: 5px;
				margin: 2px;
			}
		}

	}
	.disclaimer_container {
		font-family: Arial, Helvetica;
		display: flex;
		align-items: center;
		margin-left: '5px' ;
		font-size: 10px; /* Set the font size to 10px */
		div {
			   background-color:transparent !important;
	
			   img {
					  max-width: 50%;
					  margin:0 0 25px;
			   }
	
			   p {
					  color:white;
			   }
		}
	}

}

@media (max-width: 425px) {
	.footer-elements {
		flex-direction: column !important;
	}
}
