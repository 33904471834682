

@media (max-width: 425px) {
  .main-container {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

#incentives.button-group {

       a, button {
              background-color: #fff;
              color:#00819E;
              border:none;
              border-radius: 0px;
              font-size:16px;
              line-height: 1em;
              padding:20px 50px 15px;
              box-shadow: 0px 20px 25px 0px rgba(0, 0, 0, 0.05);

              &.active {
                     background-color:#00819E;
                     color:#fff;
              }

              &:hover {
                     text-decoration: none;
              }
       }
}